<template>
  <ServiceRadioWithMarketSegmentFormPart
    :choices="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import { solarPanelsPurchaseBE } from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioWithMarketSegmentFormPart from 'chimera/all/components/form/parts/serviceMarketSegment/ServiceRadioWithMarketSegmentFormPart.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioWithMarketSegmentFormPart,
  },

  data: () => ({
    serviceSelections: [
      new Selectable('Particulier', 'Particulier', solarPanelsPurchaseBE.id),
      new Selectable('Zakelijk', 'Zakelijk', solarPanelsPurchaseBE.id),
    ],
  }),
}
</script>
