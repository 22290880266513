<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <keep-alive>
        <template v-if="experiment && experiment.isTreatment">
          <ServiceRadioFormPartPD6796
            service-selection-title="Combineer meer optionele producten"
            @change="onChange"
            @result="onResult"
          />
        </template>
        <template v-else-if="experiment">
          <ServiceRadioFormPart @change="onChange" @result="onResult" />
        </template>
      </keep-alive>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import ServiceRadioFormPart from 'chimera/solarPanels/components/form/parts/service/ServiceRadioFormPart.be'
import ServiceRadioFormPartPD6796 from '~/components/form/parts/service/ServiceRadioFormPartPD6796.be'
import Step4 from '~/pages/offertes-aanvragen/soort-dak-schatting-jaarlijks-electriciteitsverbruik'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    ServiceRadioFormPart,
    ServiceRadioFormPartPD6796,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      experiment: null,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  /**
   * https://bambelo.atlassian.net/browse/PD-6777
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/351
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6777',
      source: ExperimentSource.ABSMARTLY,
    })
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(Step4)
    },
  },
}
</script>
