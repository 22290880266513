<template>
  <div class="service-form-part">
    <MarketSegmentRadioFormPart v-on="$listeners" />
    <h2 class="form-modal__part-title">
      {{ $t('steps.typeOfAssignment') }}
    </h2>
    <ServiceRadioField :choices="services" v-on="$listeners" />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import { solarPanelsPurchaseBE } from 'chimera/solarPanels/service'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'
import MarketSegmentRadioFormPart from 'chimera/all/components/form/parts/marketSegment/MarketSegmentRadioFormPart.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    MarketSegmentRadioFormPart,
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      services: [
        new Selectable(
          'Thuisbatterijen',
          'Thuisbatterijen',
          solarPanelsPurchaseBE.id,
        ),
        new Selectable('Airco', 'Airco', solarPanelsPurchaseBE.id),
        new Selectable(
          'Warmtepompen',
          'Warmtepompen',
          solarPanelsPurchaseBE.id,
        ),
      ],
    }
  },
}
</script>
